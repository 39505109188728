
const isNavOpen = ref(false)

export const useNav = () => {

  const toggleNav = () => {
    isNavOpen.value = !isNavOpen.value
  }

  return {
    isNavOpen,
    toggleNav
  }
}
