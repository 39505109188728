<template lang="pug">
footer
  #footer-contact.footer-contact
    .width-l
      p.footer-contact-btn
        nuxt-link(to="/forms/contact")
          | お問い合わせ
        nuxt-link(to="/forms/company-pdf")
          | 資料ダウンロード
  .footer-inner
    .footer-block.company-info
      h2
        img(src="@/assets/images/logo-white.svg" alt="ラジコード" title="ラジコードロゴ")
        | RADICODE
      p.footer-companyname 株式会社ラジコード / RADICODE inc.
      p.footer-address
        | 〒150-0002
        br.disp-tb-pc
        | 東京都渋谷区渋谷2-19-15
        br
        | 宮益坂ビルディング609
      ul.footer-sns
        li
          a(href="https://www.facebook.com/Radicode-626021381376665" target="_blank" rel="noopener" aria-label="facebook")
            font-awesome-icon(:icon="['fab', 'facebook-square']")
        li
          a(href="https://twitter.com/radicode" target="_blank" rel="noopener" aria-label="twitter")
            font-awesome-icon(:icon="['fab', 'twitter']")
        li
          a(href="https://blog.radicode.co.jp/" target="_blank" rel="noopener" aria-label="radicode blog")
            svg.icon-note(fill="none" xmlns="http://www.w3.org/2000/svg")
              g(clip-path="url(#a)")
                path(d="M5.04 2.25a1.7 1.7 0 0 0-1.7 1.69v10.12c0 .93.77 1.69 1.7 1.69h2.84v-1.13H5.04a.55.55 0 0 1-.57-.56V3.94c0-.32.25-.56.57-.56h5.06v3.37h3.37v1.13h1.13V5.95l-3.7-3.7H5.03zm6.18 1.92 1.46 1.46h-1.46V4.17zM15.96 9c-.37 0-.74.15-1.02.43l-5.2 5.3-.8 3.33 3.34-.8.1-.1 5.2-5.1c.56-.55.56-1.48 0-2.04l-.6-.6A1.43 1.43 0 0 0 15.96 9zm0 1.12c.08 0 .16.03.22.1l.6.6a.3.3 0 0 1 0 .44l-5.07 4.98-1.25.3.3-1.25 4.98-5.07a.3.3 0 0 1 .22-.1z")
              defs
                clipPath#a
                  path(fill="#fff" d="M0 0h18v18H0z")
    .footer-navs
      .footer-block.footer-nav
        nav.navs
          .nab-title
            nuxt-link(to="/subscription")
              | 定額制開発
          .nab-items
            .nav-item
              nuxt-link(to="/subscription")
                | コンセプト
            .nav-item
              nuxt-link(to="/subscription")
                | 定額制開発とは？
            .nav-item
              nuxt-link(to="/subscription/flow")
                | 契約までの流れ
            .nav-item
              nuxt-link(to="/subscription/faq")
                | よくある質問
            .nav-item
              nuxt-link(to="/wordbook")
                | ラジコード用語集
        nav.navs
          .nab-title
            nuxt-link(to="/service")
              | サービス
          .nab-items
            .nav-item
              nuxt-link(to="/service")
                | サービス概要
            .nav-item
              nuxt-link(to="/service/plan")
                | サービスプラン
            .nav-item
              nuxt-link(to="/service/casestudies")
                | サービス事例
            .nav-item
              a(href="https://jigyotukun.com/" target="_blank" rel="noopener")
                | ジギョつくん
            .nav-item
              a(href="https://lp.radilog.app/" target="_blank" rel="noopener")
                | ラジログ
            .nav-item
              a(href="https://27g.ooo/lp/" target="_blank" rel="noopener")
                | つなGO
      .footer-block.footer-nav
        nav.navs
          .nab-title
            nuxt-link(to="/works")
              | 実績
          .nab-items
            .nav-item
              nuxt-link(to="/works")
                | 実績一覧
        nav.navs
          .nab-title
            nuxt-link(to="/interviews")
              | お客様の声
          .nab-items
            .nav-item
              nuxt-link(to="/interviews")
                | インタビュー一覧
        nav.navs
          .nab-title
            nuxt-link(to="/recruit")
              | 採用情報
          .nab-items
            .nav-item
              nuxt-link(to="/recruit/#a-entry")
                | 募集職種
            .nav-item
              a(href="https://www.wantedly.com/companies/company_9848818/projects" target="_blank" rel="noopener" class="icon-blank white")
                | Wantedly
        nav.navs
          .nab-title
            nuxt-link(to="/company")
              | 会社概要
          .nab-items
            .nav-item
              nuxt-link(to="/company")
                | 会社概要
            .nav-item
              nuxt-link(to="/privacy_policy")
                | プライバシーポリシー
            .nav-item
              a(href="https://blog.radicode.co.jp/" target="_blank" rel="noopener" class="icon-blank white")
                | ブログ
</template>
<script setup lang="ts">

let footerContact: HTMLElement
let footerContactHeight: number
const onScroll = ():void => {
  if (window.scrollY > footerContactHeight) {
    footerContact.classList.add("footer-unpinned")
  } else {
    footerContact.classList.remove("footer-unpinned")
  }
}

onMounted(() => {
  footerContact = document.getElementById("footer-contact")!
  footerContactHeight = footerContact.clientHeight

  window.addEventListener("scroll", onScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener("scroll", onScroll)
})

</script>
<style lang="scss" scoped>
footer {
  background-color: $smoke_bk;
  color: $white;
  width: 100%;
  padding: 32px 16px 0;
  @include tb {
    padding: 54px 15px 0;
  }
}

.footer-inner {
  font-size: 13px;

  @include tb {
    display: flex;
    text-align: left;
    width: 100%;
    max-width: 760px;
    margin: 0 auto;
  }
}

.footer-block {
  white-space: nowrap;

  @include tb {
    padding-right: 15px;

    &:last-child {
      padding-right: 0;
    }
  }

  @include pc {
    padding-right: 15px;

    &:last-child {
      padding-right: 0;
    }
  }

  &.company-info {
    letter-spacing: 1px;

    @include tb {
      padding-right: 30px;
    }

    @include pc {
      padding-right: 60px;
    }
  }
}

.footer-companyname {
  font-size: 13px;
  margin-top: 12px;

  @include tb {
    margin-top: 10px;
  }
}

.footer-address {
  font-family: YuGothic, "Yu Gothic", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-size: 13px;
  margin-top: 3px;
  margin-bottom: 32px;
}

.footer-sns {
  display: flex;
  list-style: none;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 32px;

  @include tb {
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 0;
  }

  li {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    color: $white;
  }

  .icon-note {
    width: 15px;
    height: 16px;
    transform: scale(1.2);
    transform-origin: left center;
    margin-left: -5px;

    path {
      fill: $white;
    }
  }
}

.footer-navs {
  display: flex;
}

.footer-nav {
  width: 50%;

  @include tb {
    margin-top: 42px;
  }

  @include pc {
    width: 50%;
  }

  &:last-child {
    .nab-title {
      @include tb {
        min-width: 90px;
        max-width: 90px;
      }
    }
  }

  .nab-title {
    font-weight: bold;
    line-height: 1.3;
    margin-bottom: 20px;
    width: 100%;

    @include tb {
      margin-bottom: 0;
      width: 80px;
    }

    @include pc {
      width: 90px;
    }
  }
}

.navs {
  display: block;

  @include tb {
    display: flex;
    justify-content: flex-start;
  }

  &:not(:first-child) {
    padding-top: 16px;

    @include tb {
      padding-top: 16px;
    }
  }

  a {
    color: $white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

h2 {
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 1px;

  img {
    width: 24px;
    margin: 0 5px 0 0;
    vertical-align: bottom;
  }
}

.nav-item {
  margin: 0 0 20px;
  letter-spacing: 1px;
  line-height: 1.3;
  font-size: 13px;
  width: 40%;
  cursor: pointer;
  @include tb {
    margin: 0 2.5% 16px;
    width: 100%;
  }
}

.footer-contact {
  background: rgba($white, 0.9);
  position: fixed;
  width: 100%;
  left: 0;
  bottom: -60px;
  z-index: 20;
  transition: all 0.4s ease;

  @include tb {
    display: none;
  }

  &.footer-unpinned {
    bottom: 0;
  }

  .width-l {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: auto;

    @include tb {
      width: 86%;
      height: 70px;
      margin: 0 auto;
    }
  }
}

.footer-contact-text {
  width: 70%;
  display: none;

  @include tb {
    display: inline-flex;
    padding-right: 20px;
  }
}

.footer-contact-btn {
  display: flex;
  font-size: 14px;
  width: 100%;
  padding: 8px 20px 11px;

  @include tb {
    font-size: 16px;
  }

  a {
    background-color: $pink-red;
    border: 2px solid $pink-red;
    border-radius: 100px;
    box-shadow: 0 3px 0 darken($pink-red, 10);
    color: $white;
    font-size: 14px;
    font-weight: bold;
    flex: 1;
    display: block;
    text-align: center;
    text-decoration: none;
    position: relative;
    padding: 10px 16px;

    &:first-child {
      margin-right: 16px;
    }

    &:hover {
      background-color: $white;
      border-color: $pink-red;
      color: $pink-red;

      @include tb {
        border-color: $pink-red;
      }
    }
  }
}
</style>
