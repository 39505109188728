<template lang="pug">
div(id="burger" :class="{ active: isBurgerActive }" @click.prevent="toggle")
  slot
    button(type="button" class="burger-button" title="Menu")
      span.hidden Toggle menu
      span.burger-bar.burger-bar--1
      span.burger-bar.burger-bar--2
      span.burger-bar.burger-bar--3
</template>
<script setup lang="ts">

const { isNavOpen, toggleNav } = useNav()
const isBurgerActive = computed(() => isNavOpen.value)
const toggle = ():void => toggleNav()


</script>
<style lang="scss">
.hidden {
  visibility: hidden;
}

button {
  cursor: pointer;
}

/* remove blue outline */
button:focus {
  outline: 0;
}

.burger-button {
  position: relative;
  height: 30px;
  width: 30px;
  display: block;
  z-index: 999;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  padding: 0;
  pointer-events: all;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar {
  background-color: $smoke_bk;
  position: absolute;
  top: calc(50% - 2px);
  right: 6px;
  left: 0;
  border-radius: 10px;
  width: 30px;
  height: 4px;
  margin-top: -1px;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
  opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
  background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar--1 {
  transform: translateY(-9px);
}

.burger-bar--2 {
  transform-origin: 100% 50%;
}

.burger-bar--3 {
  transform: translateY(9px);
}

#burger.active .burger-button {
  transform: rotate(-180deg);
}

#burger.active .burger-bar {
  background-color: $smoke_bk;
}

#burger.active .burger-bar--1 {
  transform: rotate(45deg);
}

#burger.active .burger-bar--2 {
  opacity: 0;
}

#burger.active .burger-bar--3 {
  transform: rotate(-45deg);
}
</style>
