<template lang="pug">
.sidebar
  .sidebar-backdrop(@click="closeSidebarPanel" v-if="isPanelOpen")
  transition(name="slide")
    //- TODO: 下層ページ変更後にリンク修正
    .sidebar-panel(v-if="isPanelOpen")
      ul.sidebar-panel-nav
        li.sidebar-panel-nav-item
          p(:class="{ 'is-open': isSubNavOpen[1] }" @click="subNavToggle(1)")
            | 定額制開発
            span.en-subtitle SUBSCRIPTION DEVELOPMENT
          ul.sidebar-submenu-nav
            li
              nuxt-link(to="/concept" @click.native="closeSidebarPanel")
                | コンセプト
                svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                  path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
            li
              nuxt-link(to="/subscription" @click.native="closeSidebarPanel")
                | 定額制開発とは？
                svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                  path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
            li
              nuxt-link(to="/subscription/reason" @click.native="closeSidebarPanel")
                | 新規事業立ち上げと相性がいい理由
                svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                  path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
            li
              nuxt-link(to="/subscription/choose" @click.native="closeSidebarPanel")
                | 受託開発会社の選び方
                svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                  path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
              //- li
                nuxt-link(to="/subscription/price" @click.native="closeSidebarPanel")
                  | 料金プラン
                  svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                    path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
            li
              nuxt-link(to="/subscription/flow" @click.native="closeSidebarPanel")
                | 契約までの流れ
                svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                  path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
            li
              nuxt-link(to="/subscription/faq" @click.native="closeSidebarPanel")
                | よくある質問
                svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                  path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
            li
              nuxt-link(to="/wordbook" @click.native="closeSidebarPanel")
                | ラジコード用語集
                svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                  path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")

        li.sidebar-panel-nav-item
          p(:class="{ 'is-open': isSubNavOpen[2] }" @click="subNavToggle(2)")
            | サービス
            span.en-subtitle SERVICE
          ul.sidebar-submenu-nav
            li
              nuxt-link(to="/service" @click.native="closeSidebarPanel")
                | サービス概要
                svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                  path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
            li
              nuxt-link(to="/service/plan" @click.native="closeSidebarPanel")
                | サービスプラン
                svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                  path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
            li
              nuxt-link(to="/service/casestudies" @click.native="closeSidebarPanel")
                | サービス事例
                svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                  path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
            li
              a(href="https://jigyotukun.com/" target="_blank" rel="noopener")
                span.icon-blank ジギョつくん
            li
              a(href="https://lp.radilog.app/" target="_blank" rel="noopener")
                span.icon-blank ラジログ
            li
              a(href="https://27g.ooo/lp/" target="_blank" rel="noopener")
                span.icon-blank つなGO
        li.sidebar-panel-nav-item
          nuxt-link(to="/works" class="no-pd" @click.native="closeSidebarPanel")
            | 実績
            span.en-subtitle WORKS
            svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
              path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
        li.sidebar-panel-nav-item
          nuxt-link(to="/interviews" class="no-pd" @click.native="closeSidebarPanel")
            | お客様の声
            span.en-subtitle INTERVIEW
            svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
              path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
        li.sidebar-panel-nav-item
          p(:class="{ 'is-open': isSubNavOpen[5] }" @click="subNavToggle(5)")
            | 採用情報
            span.en-subtitle RECRUIT
          ul.sidebar-submenu-nav
            li
              nuxt-link(to="/recruit" @click.native="closeSidebarPanel")
                | 採用情報
                svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                  path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
            li
              nuxt-link(to="/recruit/#a-entry" @click.native="closeSidebarPanel")
                | 募集職種
                svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                  path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
            li
              a(href="https://www.wantedly.com/companies/company_9848818/projects" target="_blank" rel="noopener" @click.native="closeSidebarPanel")
                | Wantedly
                svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                  path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
        li.sidebar-panel-nav-item
          p(:class="{ 'is-open': isSubNavOpen[6] }" @click="subNavToggle(6)")
            | 会社概要
            span.en-subtitle COMPANY PROFILE
          ul.sidebar-submenu-nav
            li
              nuxt-link(to="/company" @click.native="closeSidebarPanel")
                | 会社概要
                svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                  path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
            //- li
              nuxt-link(to="/concept" @click.native="closeSidebarPanel")
                | 私たちの想い
                svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                  path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
            li
              nuxt-link(to="/privacy_policy" @click.native="closeSidebarPanel")
                | プライバシーポリシー
                svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                  path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
        li.sidebar-panel-nav-item
          a(href="https://blog.radicode.co.jp/" target="_blank" rel="noopener")
            span.icon-blank ブログ
            span.en-subtitle BLOG
      .sidebar-cta
        .sidebar-cta-item
          nuxt-link(to="/forms/contact" class="btn is-fill is-shadow")
            | お問い合わせ
        .sidebar-cta-item
          nuxt-link(to="/forms/company-pdf" class="btn is-fill is-shadow")
            | 資料ダウンロード
      .sidebar-footer
        .sidebar-footer-logo
          nuxt-link(to="/" @click.native="closeSidebarPanel")
            img.logo-mark(src="@/assets/images/logo.svg" alt="ラジコード" title="ラジコードロゴ")
            | RADICODE
        .sidebar-footer-copy
          | 定額制受託開発はラジコード
        sns-links
</template>
<script setup lang="ts">
import SnsLinks from "~/components/projects/menu/SnsLinks.vue"

const { isNavOpen, toggleNav } = useNav()

const isPanelOpen = computed(() => isNavOpen.value)
const closeSidebarPanel = ():void => toggleNav()
const isSubNavOpen = ref(Array(5).fill(false))
const subNavToggle = (index: number):void => {
  isSubNavOpen.value.splice(index, 1, !isSubNavOpen.value[index])
}

</script>
<style lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  transition: all 150ms ease-in 0s;
}

.sidebar-logo {
  font-size: 28px;
  letter-spacing: 1px;
  line-height: 50px;
  color: #000;
  width: 200px;
  font-weight: 400;
  display: flex;
  align-items: center;

  a {
    line-height: 22px;
    text-decoration: none;
  }
}

.sidebar-logo-img {
  margin: 0 5px 0 10px;
  vertical-align: top;
  width: 24px;
}

.sidebar-backdrop {
  background-color: rgba($smoke_bk, 0.6);
  box-sizing: content-box;
  width: 100vw;
  height: calc(100vh + 9px);
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 10020;
}

.sidebar-panel {
  border-top-left-radius: 30px;
  overflow-y: auto;
  background-color: $white;
  box-sizing: content-box;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10030;
  padding: 64px 30px 24px 36px;
  width: calc(100vw - 89px);
  height: calc(100vh - 79px);

  ul {
    list-style: none;
  }
}

.sidebar-panel-nav {
  margin: 0 0 28px;
  padding: 0;

  li {
    a {
      position: relative;
      text-decoration: none;
      color: $text_color;
      letter-spacing: 1px;
      padding: 12px 0;
      display: block;
    }

    a.no-pd {
      padding: 0;
    }
  }

  .icon-arrow {
    position: absolute;
    right: 0;
    top: calc(50% - 4.5px);
    width: 13px;
    height: 9px;
    transform: scale(0.846);
    margin-left: 0;
  }
}

.sidebar-panel-nav-item {
  padding-bottom: 20px;

  &:last-child {
    padding-bottom: 0;

    & > a {
      padding-top: 0;
    }
  }

  & > * {
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;

    .en-subtitle {
      color: rgba($smoke_bk, 0.3);
      display: block;
      font-size: 12px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 2px;
      margin-top: 3px;
    }
  }

  & > p {
    position: relative;
    padding-bottom: 8px;

    &:after {
      position: absolute;
      top: calc(50% - 2.5px);
      right: 0;
      content: "";
      margin-left: 8px;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 3.5px 0 3.5px;
      border-color: $smoke_bk transparent transparent transparent;
    }

    &.is-open {
      &:after {
        border-color: rgba($smoke_bk, 0.3) transparent transparent transparent;
      }

      + .sidebar-submenu-nav {
        border-bottom-color: $light_gray;
        height: auto;
        padding: 16px 0;

        &:before {
          height: 6px;
        }
      }
    }
  }

  path {
    fill: $smoke_bk;
  }
}

.sidebar-submenu-nav {
  position: relative;
  border-bottom: 1px solid transparent;
  font-size: 14px;
  font-weight: bold;
  padding: 0;
  height: 0;
  overflow: hidden;
  transition: 0.25s ease-out;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    border-radius: 10px;
    display: block;
    width: 100%;
    height: 0;
    background-color: $yellow_lighten;
  }

  li {
    position: relative;
  }
}

.sidebar-cta {
  display: flex;
  justify-content: center;
  align-items: center;

  .btn {
    width: 140px;
    height: 35px;

    @include tb {
      width: 240px;
    }
  }
}

.sidebar-cta-item {
  margin: 0 4px;
}

.sidebar-footer {
  text-align: center;
  line-height: 1;
  margin-top: 32px;

  .logo-mark {
    width: 24px;
    margin-right: 8px;
  }

  img {
    vertical-align: bottom;
  }

  a {
    font-size: 27px;
    text-decoration: none;
  }

  .sns-links {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin-top: 6px;
  }
}

.sidebar-footer-copy {
  font-size: 10px;
  font-weight: bold;
  margin-top: 8px;
}
</style>
