<template lang="pug">
.container-wrap
  def-header
  .burger-wrap
    burger
  sidebar
  slot
  div
    breadcrumb
    def-footer
    copyright-footer
    ninja-tools
</template>

<script setup lang="ts">
import DefHeader from "~/components/parts/DefHeader.vue"
import Burger from "~/components/projects/menu/RadiBurger.vue"
import Sidebar from "~/components/projects/menu/RadiSidebar.vue"
import Breadcrumb from "~/components/parts/RadiBreadcrumb.vue"
import DefFooter from "~/components/parts/DefFooter.vue"
import CopyrightFooter from "~/components/parts/CopyrightFooter.vue"
import NinjaTools from "~/components/parts/NinjaTools.vue"

useScrollDownAndFadein()
useCypress()

</script>
