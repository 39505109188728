<template lang="pug">
header(:class="{ 'is-shadow' : isAddShadow }")
  .header-inner
    .header-left
      .header-logo
        nuxt-link(to="/")
          img.header-logo-mark(src="@/assets/images/logo.svg" alt="ラジコード" title="ラジコードロゴ")
          | RADICODE
      p.header-copy
        | 定額制受託開発はラジコード
    //- TODO リンク先は下層ページ開発後に変更する
    .header-nav-wrap
      nav.header-navs.flex#global-nav
        .header-backdrop(:class="{ 'is-visible' : isMouseOverHeaderSubmenuOpen }")
        .header-nav-item
          nuxt-link(to="/subscription" class="header-nav-link-icon" @mouseover.native="onMouseOverSubMenu('subscription')" @mouseleave.native="onMouseLeaveSubMenu('subscription')")
            | 定額制開発
          .header-submenu.header-submenu-subscription(:aria-hidden="isSubscriptionAriaHidden ? 'true' : 'false'")
            .header-submenu-inner-1(@mouseover="onMouseOverSubMenu('subscription')" @mouseleave="onMouseLeaveSubMenu('subscription')")
              .header-submenu-inner-2
                div
                  ul.header-submenu-nav
                    li
                      nuxt-link(to="/concept")
                        | コンセプト
                        svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                          path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
                    li
                      nuxt-link(to="/subscription")
                        | 定額制開発とは？
                        svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                          path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
                    li
                      nuxt-link(to="/subscription/reason")
                        | 新規事業立ち上げと相性がいい理由
                        svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                          path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
                    li
                      nuxt-link(to="/subscription/choose")
                        | 受託開発会社の選び方
                        svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                          path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
                      //- li
                        nuxt-link(to="/subscription/price")
                          | 料金プラン
                          svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                            path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
                    li
                      nuxt-link(to="/subscription/flow")
                        | 契約までの流れ
                        svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                          path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
                    li
                      nuxt-link(to="/subscription/faq")
                        | よくある質問
                        svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                          path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
                    li
                      nuxt-link(to="/wordbook")
                        | ラジコード用語集
                        svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                          path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
        .header-nav-item
          nuxt-link(to="/service" class="header-nav-link-icon" @mouseover.native="onMouseOverSubMenu('service')" @mouseleave.native="onMouseLeaveSubMenu('service')")
            | サービス
          .header-submenu.header-submenu-service(:aria-hidden="isServiceAriaHidden ? 'true' : 'false'")
            .header-submenu-inner-1(@mouseover="onMouseOverSubMenu('service')" @mouseleave="onMouseLeaveSubMenu('service')")
              .header-submenu-inner-2
                div
                  ul.header-submenu-nav
                    li
                      nuxt-link(to="/service")
                        | サービス概要
                        svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                          path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
                    li
                      nuxt-link(to="/service/plan")
                        | サービスプラン
                        svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                          path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
                    li
                      nuxt-link(to="/service/casestudies")
                        | サービス事例
                        svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                          path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
                    li
                      a(href="https://jigyotukun.com/" target="_blank" rel="noopener")
                        span.icon-blank ジギョつくん
                    li
                      a(href="https://lp.radilog.app/" target="_blank" rel="noopener")
                        span.icon-blank ラジログ
                    li
                      a(href="https://27g.ooo/lp/" target="_blank" rel="noopener")
                        span.icon-blank つなGO
        .header-nav-item
          nuxt-link(to="/works" class="header-nav-link")
            | 実績
        .header-nav-item
          nuxt-link(to="/interviews" class="header-nav-link")
            | お客様の声
        .header-nav-item
          nuxt-link(to="/recruit" class="header-nav-link-icon" @mouseover.native="onMouseOverSubMenu('recruit')" @mouseleave.native="onMouseLeaveSubMenu('recruit')")
            | 採用情報
          .header-submenu.header-submenu-recruit(:aria-hidden="isRecruitAriaHidden ? 'true' : 'false'")
            .header-submenu-inner-1(@mouseover="onMouseOverSubMenu('recruit')" @mouseleave="onMouseLeaveSubMenu('recruit')")
              .header-submenu-inner-2
                div
                  ul.header-submenu-nav
                    li
                      nuxt-link(to="/recruit")
                        | 採用情報
                        svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                          path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
                    li
                      nuxt-link(to="/recruit/#a-entry")
                        | 募集職種
                        svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                          path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
                    li
                      a(href="https://www.wantedly.com/companies/company_9848818/projects" target="_blank" rel="noopener")
                        | Wantedly
                        svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                          path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
        .header-nav-item
          nuxt-link(to="/company" class="header-nav-link-icon" @mouseover.native="onMouseOverSubMenu('company')" @mouseleave.native="onMouseLeaveSubMenu('company')")
            | 会社概要
          .header-submenu.header-submenu-company(:aria-hidden="isCompanyAriaHidden ? 'true' : 'false'")
            .header-submenu-inner-1(@mouseover="onMouseOverSubMenu('company')" @mouseleave="onMouseLeaveSubMenu('company')")
              .header-submenu-inner-2
                div
                  ul.header-submenu-nav
                    li
                      nuxt-link(to="/company")
                        | 会社概要
                        svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                          path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
                    //- li
                      nuxt-link(to="/concept")
                        | 私たちの想い
                        svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                          path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
                    li
                      nuxt-link(to="/privacy_policy")
                        | プライバシーポリシー
                        svg(class="icon-arrow" fill="none" xmlns='http://www.w3.org/2000/svg')
                          path(fill-rule="evenodd" clip-rule="evenodd" d="m8 8.5 5-4.25L8 0v3.23H0v2h8V8.5Z")
                    li
                      a(href="https://blog.radicode.co.jp/" target="_blank" rel="noopener")
                        span.icon-blank ブログ
        .header-nav-item
          nuxt-link(to="/forms/company-pdf" class="btn is-fill")
            | 資料ダウンロード
        .header-nav-item
          nuxt-link(to="/forms/contact" class="btn is-fill")
            | お問い合わせ
</template>
<script setup lang="ts">

const scrollMaxY = ref(200)
const scrollY = ref(0)
const isMouseOverHeaderSubmenuOpen = ref(false)
const isSubscriptionAriaHidden = ref(true)
const isServiceAriaHidden = ref(true)
const isWorksAriaHidden = ref(true)
const isInterviewsAriaHidden = ref(true)
const isCompanyAriaHidden = ref(true)
const isRecruitAriaHidden = ref(true)
const timer = ref<number | null>(null)

const isAddShadow = computed(() => {
  if (scrollY.value === 0) return false
  const threshold = scrollMaxY.value
  return threshold <= scrollY.value
})

const onMouseOverSubMenu = (selectedItem: string):void => {
  isMouseOverHeaderSubmenuOpen.value = true

  if (selectedItem === "subscription") {
    isSubscriptionAriaHidden.value = false
  } else if (selectedItem === "service") {
    isServiceAriaHidden.value = false
  } else if (selectedItem === "works") {
    isWorksAriaHidden.value = false
  } else if (selectedItem === "company") {
    isCompanyAriaHidden.value = false
  } else if (selectedItem === "recruit") {
    isRecruitAriaHidden.value = false
  } else if (selectedItem === "interviews") {
    isInterviewsAriaHidden.value = false
  }
}

const onMouseLeaveSubMenu = (selectedItem: string):void => {
  isMouseOverHeaderSubmenuOpen.value = false

  if (selectedItem === "subscription") {
    isSubscriptionAriaHidden.value = true
  } else if (selectedItem === "service") {
    isServiceAriaHidden.value = true
  } else if (selectedItem === "works") {
    isWorksAriaHidden.value = true
  } else if (selectedItem === "company") {
    isCompanyAriaHidden.value = true
  } else if (selectedItem === "recruit") {
    isRecruitAriaHidden.value = true
  } else if (selectedItem === "interviews") {
    isInterviewsAriaHidden.value = true
  }
}

const handleScroll = () :void => {
  // MEMO: スクロールイベント全てで発火させると負荷が高いので200msごとの判定にしておく
  if (timer.value) return
  timer.value = window.setTimeout(() => {
    timer.value = null
    // MEMO: 現在のスクロール位置
    scrollY.value = window.pageYOffset || document.documentElement.scrollTop
  }, 200)
}

onMounted(() => {
  handleScroll()
  window.addEventListener("scroll", handleScroll)

  const calcSelectors = ():string| null => {
    const route = useRoute()
    const routepath = route.path
    if (routepath.match(/subscription/)) {
      return "a[href='/subscription']"
    } else if (routepath.match(/service/)) {
      return "a[href='/service']"
    } else if (routepath.match(/works/)) {
      return "a[href='/works']"
    } else if (routepath.match(/company/)) {
      return "a[href='/company']"
    } else {
      return null
    }
  }
  const selectors = calcSelectors()
  if (selectors !== null) {
    document.querySelector(selectors)!.classList.add("active")
  }

  const boxes = document.querySelectorAll(".box-current-check")
  const options = {
    root: null,
    rootMargin: "-50% 0px",
    threshold: 0
  }
  const doWhenIntersect = (entries: IntersectionObserverEntry[]):void => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        activateIndex(entry.target)
      }
    })
  }
  const observer = new IntersectionObserver(doWhenIntersect, options)
  boxes.forEach(box => {
    observer.observe(box)
  })

  const activateIndex = (element: Element):void => {
    const currentActiveIndex = document.querySelector("#global-nav .active")
    if (currentActiveIndex !== null) {
      currentActiveIndex.classList.remove("active")
    }
    if (element.id !== "") {
      if (element.id === "a-service") {
        document.querySelector("a[href='/service']")!.classList.add("active")
      } else if (element.id === "a-feature") {
        document.querySelector("a[href='/feature']")!.classList.add("active")
      } else {
        const newActiveIndex = document.querySelector(`a[href='/#${element.id}']`)
        newActiveIndex!.classList.add("active")
      }
    }
  }
})

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll)
})

</script>
<style lang="scss" scoped>
header {
  background-color: rgba($white, 0);
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9950;
  transition: 0.25s ease-out;
  max-width: 100vw;

  &.is-shadow {
    background-color: rgba($white, 1);
    box-shadow: 0px 2px 20px rgba($bk, 0.15);
  }
}

.header-inner {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  line-height: 50px;

  @include pc {
    height: 80px;
    line-height: 80px;
  }
}

.header-left {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  font-size: 10px;
  font-weight: bold;
  padding-left: 1.7vw;

  @include pc {
    align-items: center;
    font-size: 12px;
    flex-direction: row;
  }

  @media (min-width: 1024px) and (max-width: 1300px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.header-logo {
  box-sizing: border-box;
  font-size: 28px;
  letter-spacing: 1px;
  line-height: 50px;
  color: $bk;
  font-weight: 400;
  padding-right: 1vw;
  order: 2;

  @include pc {
    line-height: 80px;
    order: 1;
  }

  @media (min-width: 1024px) and (max-width: 1300px) {
    order: 2;
  }

  a {
    display: flex;
    align-items: center;
    color: $bk;
    line-height: 22px;
    text-decoration: none;
  }
}

.header-logo-mark {
  width: 24px;
  margin: 0 5px 0 0;
  vertical-align: top;
}

.header-copy {
  line-height: 1;
  order: 1;
  margin-bottom: 2px;
  transform: scale(0.9);
  transform-origin: center;
  font-size: 11px;

  @include pc {
    order: 2;
    margin-bottom: 0;
    transform: scale(1);
  }

  @media (min-width: 1024px) and (max-width: 1300px) {
    order: 1;
    margin-bottom: 4px;
  }
}

.header-nav-wrap {
  display: none;
  font-size: 14px;
  font-weight: bold;
  @include pc {
    display: flex;
    justify-content: flex-end;
    padding-right: 29px;
  }
}

.header-navs {
  align-items: center;
}

.header-nav-item {
  position: relative;
  letter-spacing: 1px;
  cursor: pointer;
  margin-left: 1.7vw;

  &:nth-child(2) {
    margin-left: 0;
  }

  a {
    text-decoration: none;
  }

  .btn {
    font-size: 14px;
    width: 145px;
    height: 45px;
    box-shadow: 3px 4px 0 darken($pink-red, 10);
  }

  &:last-child {
    margin-left: 1vw;
    .btn {
      width: 120px;
    }
  }
}

.header-nav-link {
  position: relative;
  display: block;

  &:hover {
    opacity: 0.6;
  }
}

.header-nav-link-icon {
  position: relative;
  display: block;
  pointer-events: auto;
  padding-right: 12px;

  &:after {
    position: absolute;
    top: calc(50% - 2.5px);
    right: 0;
    content: "";
    margin-left: 8px;
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 3.5px 0 3.5px;
    border-color: $smoke_bk transparent transparent transparent;
  }

  &:hover {
    opacity: 0.6;
  }
}

.header-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($smoke_bk, 0.3);
  pointer-events: none;

  &:not(.is-visible) {
    visibility: hidden;
    opacity: 0;
    transition: 0.2s ease-out;
  }
}

.header-submenu {
  position: absolute;
  left: -25px;
  top: 64px;
  z-index: 1;
  cursor: default;
  transition: 0.2s ease-out;

  &[aria-hidden="true"] {
    transform: translateY(0.5rem);
    opacity: 0;
    visibility: hidden;
    transition: 0.2s ease-out;
  }

  &[aria-hidden="false"] {
    transition: 0.2s ease-out;
  }

  &-subscription {
    .header-submenu-inner-1 {
      width: 350px;
    }
  }

  &-service {
    .header-submenu-inner-1 {
      width: 320px;
    }
  }

  &-works {
    .header-submenu-inner-1 {
      width: 150px;
    }
  }

  &-interviews {
    .header-submenu-inner-1 {
      width: 310px;
    }
  }

  &-recruit {
    .header-submenu-inner-1 {
      width: 160px;
    }
  }

  &-company {
    .header-submenu-inner-1 {
      width: 270px;
    }
  }
}

.header-submenu-inner-1 {
  position: relative;
  z-index: 1;
  background-color: $white;
  border-radius: 10px;
  padding: 12px 16px 12px 18px;
  pointer-events: auto;
}

.header-submenu-inner-2 {
  position: relative;

  > * {
    padding: 0 0 0 8px;

    &:nth-child(2) {
      border-right: 1px solid $light_gray;
    }

    &:last-child {
      border-right-width: 0;
    }
  }

  h2 {
    width: 212px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    font-size: 16px;
    line-height: 44px;
  }

  svg {
    transition: 0.2s ease-out;
  }

  a {
    display: flex;
    align-items: center;

    &:hover {
      opacity: 0.7;

      svg {
        margin-left: 12px;
      }
    }
  }
}

.header-submenu-heading {
  font-size: 20px;
  line-height: 1;

  + span {
    color: rgba($smoke_bk, 0.2);
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    display: block;
    margin-top: 16px;
  }
}

.header-submenu-nav {
  .icon-arrow {
    width: 13px;
    height: 9px;
    transform: scale(0.846);
    margin-left: 8px;
  }

  path {
    fill: $smoke_bk;
  }
}
</style>
